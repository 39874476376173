<template>
  <div class="show-more" :style="{ '--maxHeight': `${maxHeight}px` }">
    <div
      ref="wysiwygDesc"
      class="desc wysiwyg-desc"
      :class="{ overflow: isDescOverflow, full: !isDescOverflow }"
      v-html="content"
    ></div>
    <div v-if="isDescOverflow" class="desc-overlay"></div>
    <div v-if="hasShowMore" class="d-flex justify-content-center mt-2">
      <button class="btn bordered main show-more" @click="toggleSeeMoreDesc">
        {{ isDescOverflow ? "Show More" : "Show Less" }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    content: {
      type: String,
      default: ""
    },
    maxHeight: {
      type: Number,
      default: 150
    }
  },
  data: function () {
    return {
      isDescOverflow: false,
      hasShowMore: false,

      descObserver: null
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initDescObserver();
  },
  methods: {
    toggleSeeMoreDesc() {
      this.isDescOverflow = !this.isDescOverflow;
    },
    initDescObserver() {
      if (this.$refs.wysiwygDesc !== null) {
        let height = this.$refs.wysiwygDesc.offsetHeight;
        if (height > this.maxHeight) {
          this.isDescOverflow = true;
          this.hasShowMore = true;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.show-more {
  .desc {
    color: #656565;

    &.overflow {
      max-height: var(--maxHeight);
      overflow-y: hidden;
    }
    &.full {
      max-height: initial;
      overflow-y: auto;
    }
  }
  .desc-overlay {
    background: linear-gradient(0deg, white, transparent);
    height: 50px;
    position: relative;
    bottom: 50px;
    margin-bottom: -50px;
  }
}
</style>
